import {
  Button,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemText,
  makeStyles
} from "@material-ui/core";
import * as PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby-theme-material-ui";
import logo from "../images/logo_white.svg";
import { White } from "./White";

const useStyles = makeStyles(theme => ({
  button: {
    paddingTop: `${(9 / 16) * 35}%`,
    paddingLeft: theme.spacing(4),
    paddingBottom: theme.spacing(6),
    paddingRight: `${(9 / 16) * 97}%`
  },
  features: {
    display: "flex",
    flexDirection: "column"
  },
  flex: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end"
  },
  footer: {
    background: theme.palette.primary.main
  },
  header: {
    background: `linear-gradient(0deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
    paddingBottom: `${(9 / 16) * 100}%`,
    height: 0
  },
  list: {
    display: "flex",
    flexDirection: "column"
  },
  footerItem: {
    color: "#fff",
    paddingLeft: theme.spacing(4),
    "&$selectedItem": {
      "& .MuiListItemText-primary": {
        color: theme.palette.text.secondary
      }
    }
  },
  listItem: {
    color: theme.palette.text.secondary,
    paddingLeft: theme.spacing(4),
    "&$selectedItem": {
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.action.selected
          : "rgba(255, 255, 255, 0.1)",
      "& .MuiListItemText-primary": {
        color: theme.palette.primary.main
      }
    },
    "& .MuiListItemIcon-root": {
      marginRight: theme.spacing(-2)
    }
  },
  link: {
    fontWeight: 500
  },
  linkHovered: {
    color: theme.palette.text.secondary
  },
  logo: {
    height: 40
  },
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: 240
  },
  subItem: {
    color: theme.palette.text.secondary,
    paddingLeft: theme.spacing(6),
    "&$selectedItem": {
      "& .MuiListItemText-primary": {
        color: theme.palette.primary.main
      },
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.action.selected
          : "rgba(255, 255, 255, 0.1)"
    }
  },
  text: {
    fontWeight: 500
  }
}));

function Sidenav({ open, onClose, onOpen }) {
  const classes = useStyles();

  return (
    <SwipeableDrawer open={open} onOpen={onOpen} onClose={onClose}>
      <div className={classes.root}>
        <div className={classes.header}>
          <Button
            classes={{ text: classes.button }}
            component={props => <Link to={"/"} {...props} />}
            onClick={onClose}
          >
            <img alt={"Logo"} className={classes.logo} src={logo} />
          </Button>
        </div>
        <List className={classes.list} component={"nav"}>
          <ListItem
            button
            className={classes.listItem}
            component={Link}
            dense
            to={"/"}
          >
            <ListItemText classes={{ primary: classes.text }}>
              Home
            </ListItemText>
          </ListItem>
          <ListItem
            button
            className={classes.listItem}
            component={Link}
            dense
            to={"/services"}
          >
            <ListItemText classes={{ primary: classes.text }}>
              Leistungen
            </ListItemText>
          </ListItem>
          <ListItem
            button
            className={classes.listItem}
            component={Link}
            dense
            to={"/games"}
          >
            <ListItemText classes={{ primary: classes.text }}>
              Games
            </ListItemText>
          </ListItem>
        </List>
        <White>
          <div className={classes.flex}>
            <List className={classes.footer}>
              <ListItem
                className={classes.footerItem}
                component={Link}
                dense
                to={"/contact"}
              >
                <ListItemText classes={{ primary: classes.link }}>
                  Kontakt
                </ListItemText>
              </ListItem>
              <ListItem
                className={classes.footerItem}
                component={Link}
                dense
                to={"/impressum"}
              >
                <ListItemText classes={{ primary: classes.link }}>
                  Impressum
                </ListItemText>
              </ListItem>
            </List>
          </div>
        </White>
      </div>
    </SwipeableDrawer>
  );
}

Sidenav.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired
};

export default Sidenav;
