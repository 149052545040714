import React from "react";
import { createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import * as PropTypes from "prop-types";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#fff"
    },
    type: "dark"
  },
  overrides: {
    MuiTypography: {
      subtitle1: {
        color: "#fff"
      },
      body1: {
        color: "#fff"
      },
      body2: {
        color: "#fff"
      },
      h1: {
        color: "#fff"
      },
      h2: {
        color: "#fff"
      },
      h3: {
        color: "#fff"
      },
      h4: {
        color: "#fff"
      },
      h5: {
        color: "#fff"
      },
      h6: {
        color: "#fff"
      }
    }
  }
});

export function White({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

White.propTypes = {
  children: PropTypes.node
};
