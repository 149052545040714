/**
 * Custom resolver for internal links by document
 * @param doc
 * @return {string}
 */
const linkResolver = doc => {
  if (doc.type === "home") {
    return "/";
  }

  return `/${doc.type}`;
};

export default linkResolver;
