import React, { useEffect, useState } from "react";
import * as PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { NoSsr, useMediaQuery } from "@material-ui/core";
import Header from "./Header";
import Footer from "./Footer";
import Sidenav from "./Sidenav";
import "./App.css";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    minHeight: "100%"
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flex: "1 auto"
  }
}));

const Layout = ({ children }) => {
  const classes = useStyles();
  const [headerDocked, setHeaderDocked] = useState(false);
  const [open, setMenuOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width:760px)");

  useEffect(() => {
    const checkIsHeaderVisible = event => {
      const value = event.target.scrollingElement.scrollTop;
      const offset = 64;

      if (value >= offset && !headerDocked) {
        setHeaderDocked(true);
      } else if (value < offset && headerDocked) {
        setHeaderDocked(false);
      }
    };

    window.addEventListener("scroll", checkIsHeaderVisible);

    return function cleanup() {
      window.removeEventListener("scroll", checkIsHeaderVisible);
    };
  });

  return (
    <NoSsr>
      <div className={classes.root}>
        <CssBaseline />
        <Header docked={headerDocked} onBurgerClick={() => setMenuOpen(true)} />
        <div className={classes.content}>{children}</div>
        <Footer />
        <Sidenav
          setMenuOpen={setMenuOpen}
          onOpen={() => setMenuOpen(true)}
          onClose={() => setMenuOpen(false)}
          open={open}
        />
      </div>
    </NoSsr>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
