import React from "react";
import { Button, Divider, makeStyles, Typography } from "@material-ui/core";
import cx from "classnames";
import { Link } from "gatsby-theme-material-ui";
import instagram from "../images/insta.png";
import twitter from "../images/twitter.png";
import facebook from "../images/facebook.png";

const useStyles = makeStyles(theme => ({
  button: {},
  copyright: {
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    padding: 16
  },
  divider: {
    backgroundColor: "#fff",
    flex: 2,
    marginRight: 8
  },
  information: {
    backgroundColor: theme.palette.primary.light,
    display: "flex",
    height: 100,
    padding: 16
  },
  logo: {
    cursor: "pointer",
    height: 40,
    marginRight: 8,
    width: "auto"
  },
  nav: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: 16
  },
  noMargin: {
    margin: 0
  },
  root: {
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    flexDirection: "column",
    padding: "16px 0"
  },
  social: {
    height: 32,
    marginRight: 8,
    width: 32
  },
  socialContainer: {
    alignItems: "center",
    display: "flex",
    marginBottom: 16,
    width: "100%"
  },
  whiteFont: {
    color: "#fff"
  }
}));

function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.nav}>
        <Button
          className={cx(classes.button, classes.whiteFont)}
          component={Link}
          to={"/contact"}
        >
          Kontakt
        </Button>
        <Button
          className={cx(classes.button, classes.whiteFont)}
          component={Link}
          to={"/impressum"}
        >
          Impressum
        </Button>
        <Button
          className={cx(classes.button, classes.whiteFont)}
          component={Link}
          to={"/terms"}
        >
          Datenschutzbestimmungen
        </Button>
      </div>
      <div className={classes.socialContainer}>
        <Divider className={classes.divider} />
        <a href="https://www.facebook.com/Vollkorn-Games-1809386565742782/">
          <img src={facebook} className={classes.social} />
        </a>
        <a href="https://twitter.com/VollkornGames">
          <img src={twitter} className={classes.social} />
        </a>
        <a href="https://www.instagram.com/vollkorngames/">
          <img src={instagram} className={classes.social} />
        </a>
        <Divider className={cx(classes.divider, classes.noMargin)} />
      </div>
      <Typography className={classes.whiteFont} variant="caption">
        @ {new Date(Date.now()).getFullYear()} - Vollkorn Games
      </Typography>
    </div>
  );
}

export default Footer;
