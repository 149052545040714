import React from "react";
import * as PropTypes from "prop-types";
import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  useMediaQuery
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "gatsby-theme-material-ui";
import { Menu } from "mdi-material-ui";
import logo from "../images/logo.svg";

const useStyles = makeStyles(theme => ({
  root: {
    borderTop: `${theme.spacing(0.5)} solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.background.default,
    position: "sticky"
  },
  logo: {
    cursor: "pointer",
    height: 40,
    marginRight: theme.spacing(1),
    width: "auto"
  },
  nav: {
    display: "flex"
  },
  navButton: {
    margin: theme.spacing(0, 1)
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0 auto",
    maxWidth: 1200,
    width: "100%"
  },
  menuButton: {
    marginLeft: theme.spacing(-1.5),
    marginRight: theme.spacing(2.5)
  }
}));

function Header({ docked, onBurgerClick }) {
  const isMobile = useMediaQuery("(max-width:760px)");
  const classes = useStyles();

  return (
    <AppBar className={classes.root} elevation={docked ? 3 : 0}>
      <Toolbar className={classes.toolbar}>
        {isMobile ? (
          <IconButton
            className={classes.menuButton}
            color="primary"
            aria-label="Menu"
            onClick={onBurgerClick}
          >
            <Menu />
          </IconButton>
        ) : (
          <Button component={props => <Link to={"/"} {...props} />}>
            <img alt={"logo"} className={classes.logo} src={logo} />
          </Button>
        )}
        <div className={classes.nav}>
          <Button
            className={classes.navButton}
            component={Link}
            to={"/services"}
          >
            Leistungen
          </Button>
          <Button className={classes.navButton} component={Link} to={"/games"}>
            Games
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  docked: PropTypes.bool
};

export default Header;
